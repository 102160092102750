var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"fill-height"},[_c('TheTopbar',{attrs:{"is-search-open":_vm.isSearchOpen},on:{"search-deactivate":_vm.closeSearch,"search-text-update":_vm.handleSearchTextUpdate,"sidebar-hide":function($event){return _vm.$store.dispatch('app/closeSidebar')},"sidebar-show":function($event){return _vm.$store.dispatch('app/openSidebar')},"sidebar-toggled":_vm.handleSidebarToggled}}),_c('TheSidebar',{ref:"the-sidebar",attrs:{"sidebar":_vm.isSidebarOpen,"width":_vm.$cr.sidebarWidth},on:{"sidebar-hide":function($event){return _vm.$store.dispatch('app/closeSidebar')},"sidebar-show":function($event){return _vm.$store.dispatch('app/openSidebar')},"sidebar-toggled":_vm.handleSidebarToggled}}),(_vm.hasCallCenter)?_c('TheCallCenter',{attrs:{"sidebar-width":_vm.$cr.sidebar.width,"is-sidebar-open":_vm.isSidebarOpen}}):_vm._e(),_c('TheSearchResults',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchOpen),expression:"isSearchOpen"}],attrs:{"search-text":_vm.searchText},on:{"search-close":_vm.closeSearch}}),_c('TheAlerts'),_c('TheDialog',{attrs:{"dialog-component":_vm.dialogComponent,"show-dialog":_vm.showDialog,"dialog-data":_vm.dialogData}}),_c('TheSidebarDialog',{attrs:{"dialog-component":_vm.sidebarDialogComponent,"show-dialog":_vm.showSidebarDialog,"dialog-data":_vm.sidebarDialogData,"persistent":_vm.persistent}}),_c('v-content',{staticStyle:{"margin":"0"},attrs:{"column":"","fill-height":""}},[_c('router-view')],1),(_vm.elementIsHighlighted)?_c('div',{staticClass:"app-highlight",style:({
      borderTopWidth: `${Math.trunc(_vm.highlightedElementDimensions.top)}px`,
      borderRightWidth: `${Math.trunc(
        _vm.highlightedElementDimensions.right + 5
      )}px`,
      borderBottomWidth: `${Math.trunc(
        _vm.highlightedElementDimensions.bottom
      )}px`,
    }),on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('app/clearHighlightedElement')}}},[_c('div',{staticClass:"app-highlight-inner"},[_c('v-popover',{style:({
          height: `${Math.trunc(_vm.highlightedElementDimensions.height)}px`,
        }),attrs:{"open":!!_vm.elementIsHighlighted,"offset":Math.trunc(_vm.highlightedElementDimensions.width + 10),"trigger":"manual","placement":"right"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('h2',[_vm._v(_vm._s(_vm.highlightedElementText.header))]),_c('p',[_vm._v(_vm._s(_vm.highlightedElementText.body))])]},proxy:true}],null,false,3807458461)},[_c('button',{style:({
            height: `${Math.trunc(_vm.highlightedElementDimensions.height)}px`,
          })},[_vm._v("   ")])])],1)]):_vm._e(),_c('FlexContainer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }