<template>
  <v-layout
    v-show="$cr.breakpoint.smAndUp"
    row
    wrap
    align-center
    class="call-center"
    :class="callCenterStatusClass"
    :style="`
      width: calc(100% - ${
        isSidebarOpen ? 156 + sidebarWidth + 42 : 156 + sidebarWidth - 6
      }px);
      margin-left: ${isSidebarOpen ? sidebarWidth + 24 : sidebarWidth - 24}px;
  `"
  >
    <v-flex shrink order-lg1 order-xs1>
      <v-layout align-center row>
        <v-flex shrink>
          <div class="icon-circle" @click="debugFileCountdown">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="!isAuthenticated"
                  v-bind="attrs"
                  :class="userStatusClass"
                  v-on="on"
                >
                  priority_high
                </v-icon>
                <v-icon
                  v-else-if="isRinging && isIncomingChat"
                  v-bind="attrs"
                  :class="userStatusClass"
                  v-on="on"
                >
                  forum
                </v-icon>
                <v-icon
                  v-else-if="isCalling"
                  v-bind="attrs"
                  :class="userStatusClass"
                  v-on="on"
                >
                  phone_forwarded
                </v-icon>
                <v-icon
                  v-else-if="hasChat || hasLastChat"
                  v-bind="attrs"
                  :class="userStatusClass"
                  v-on="on"
                >
                  forum
                </v-icon>
                <v-icon
                  v-else
                  v-bind="attrs"
                  :class="userStatusClass"
                  v-on="on"
                >
                  phone
                </v-icon>
              </template>
              <span>
                {{ statusTooltipText }}
              </span>
            </v-tooltip>
          </div>
        </v-flex>
        <v-layout column class="status-container">
          <v-flex v-if="!isAuthenticated || call" shrink class="display-status">
            {{ callCenterStatusText }}
            <span
              v-if="
                isAuthenticated && (hasMissedCall || showLastCall) && !hasChat
              "
              class="call-time"
            >
              ({{ callTimeDisplayText }})
            </span>
            <v-icon
              v-if="!isAuthenticated"
              color="white"
              style="margin-bottom: -2px"
              @click="getUserFromFlex"
            >
              replay
            </v-icon>
          </v-flex>
          <v-flex
            v-if="
              isAuthenticated &&
              (isRA || isRAManager) &&
              call.currentQueue &&
              !hasChat &&
              !isIncomingChat &&
              !hasLastChat
            "
            shrink
            class="subtitle-text"
          >
            {{
              call.queueTransferredFrom
                ? `${call.queueTransferredFrom} > ${call.currentQueue}`
                : call.currentQueue
            }}
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>
    <v-flex
      id="chatNames"
      shrink
      order-lg2
      order-xs3
      v-if="isAuthenticated && (hasChat || isIncomingChat)"
    >
      <v-chip
        v-if="isIncomingChat"
        label
        :color="$cr.theme.callerInfoChip"
        class="caller-details"
      >
        {{ incomingChatName || 'Unknown user' }}
      </v-chip>
      <v-chip
        v-for="(chat, chatIndex) in chats"
        :key="`chat-${chatIndex}-${chat.channelSid}`"
        label
        :color="$cr.theme.callerInfoChip"
        class="caller-details"
      >
        ({{ chatIndex + 1 }}/3) {{ chat.name || 'Unknown user' }}
      </v-chip>
    </v-flex>

    <v-flex
      id="lastChat"
      shrink
      order-lg2
      order-xs3
      v-else-if="isAuthenticated && hasLastChat"
    >
      <v-chip
        v-if="hasLastChat"
        label
        :color="$cr.theme.callerInfoChip"
        class="caller-details"
      >
        {{ lastChat.name || 'Unknown user' }}
      </v-chip>
    </v-flex>

    <v-flex
      v-else-if="isAuthenticated && call.callerPhone"
      id="callPhoneNumbers"
      shrink
      order-lg2
      order-xs3
    >
      <v-chip label :color="$cr.theme.callerInfoChip" class="caller-details">
        <!-- COACH-539: trick dialpad extension using sw-phone css class -->
        <div class="sw-phone">{{ callerPhoneDisplayText }}</div>
      </v-chip>
      <div
        class="d-inline-flex align-items-center"
        style="vertical-align: middle"
      >
        <template v-if="isVipCaller">
          <CRIcon
            id="vip-caller-icon"
            class="margin-l-2"
            height="24"
            width="72"
            view-box="0 0 72 24"
          >
            vip
          </CRIcon>
        </template>
        <template v-else-if="isCharterUpPlusCaller">
          <CRIcon
            id="charter_up_plus-caller-icon"
            class="margin-l-2"
            height="24"
            width="54"
            view-box="0 0 54 24"
          >
            charter_up_plus
          </CRIcon>
        </template>
      </div>
      <v-icon
        v-if="
          isAuthenticated &&
          call.brandPhone &&
          (isSDR ||
            isSDRManager ||
            isCallDirectToRAManager ||
            isParentBrandsCallCenterEnabled)
        "
        color="white"
        class="caller-details-arrow"
      >
        mdi-arrow-right
      </v-icon>
      <v-chip
        v-if="
          isAuthenticated &&
          call.brandPhone &&
          (isSDR ||
            isSDRManager ||
            isCallDirectToRAManager ||
            isParentBrandsCallCenterEnabled)
        "
        label
        color="transparent"
        class="caller-details"
      >
        <div class="sw-phone">
          {{ brandCallerIdDisplayText }}
          <span v-if="isSDR || isSDRManager || isCallDirectToRAManager">
            {{ brandPhoneDisplayText }}
          </span>
        </div>
      </v-chip>
    </v-flex>
    <v-flex grow order-lg3 order-xs2></v-flex>
    <v-flex shrink order-lg4 order-xs4>
      <v-layout align-center row>
        <v-btn
          v-if="
            (!isAuthenticated && !twilioFlexInstanceOverride) || isAuthenticated
          "
          id="openFlexButton"
          color="white"
          outline
          @click="findFlexTab"
        >
          <v-icon color="white">mdi-open-in-new</v-icon>
        </v-btn>
        <v-select
          v-if="showUserStatusDropDown"
          :value="userStatus"
          hide-details="auto"
          outline
          dense
          append-icon="keyboard_arrow_down"
          :hide-selected="hideStatusInDropDown"
          :items="userStatusesDropDownItems"
          @change="updateFlexUserStatus"
        >
          <template #selection>
            <v-layout align-center row>
              <v-flex>
                <div
                  class="icon-status-circle"
                  :class="userStatusIconClass"
                ></div>
              </v-flex>
              <v-flex class="status-box">
                {{ userStatus }}
              </v-flex>
            </v-layout>
          </template>
        </v-select>

        <v-btn
          v-if="showRequeueCallbackButton"
          outline
          color="white"
          @click="requeueCallback"
        >
          Requeue({{ callback.placeCallRetry }})
        </v-btn>

        <v-btn
          v-if="showCompleteCallbackButton"
          color="primary"
          @click="completeCallback"
        >
          Complete Callback
        </v-btn>

        <v-btn
          v-if="isSDR && isAuthenticated && isOnCall && !hasChat"
          color="white"
          outline
          @click="endCall"
        >
          End Call
        </v-btn>
        <v-btn
          v-if="isAuthenticated && isOnCall && !hasChat"
          class="icon-btn"
          :color="!isOnMute ? 'white' : $cr.theme.muteRed"
          :outline="!isOnMute"
          @click="toggleMute"
        >
          <v-icon color="white">
            {{ isOnMute ? 'mdi-volume-off' : 'mdi-volume-high' }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="isAuthenticated && isOnCall && !isOnHold && !hasChat"
          class="icon-btn"
          color="white"
          outline
          @click="putCallOnHold"
        >
          <v-icon color="white">mdi-pause</v-icon>
        </v-btn>
        <v-btn
          v-if="isAuthenticated && isOnCall && isOnHold && !hasChat"
          class="icon-btn"
          :color="$cr.theme.holdYellow"
          @click="resumeCall"
        >
          <v-icon color="white">mdi-pause</v-icon>
        </v-btn>
        <v-btn
          v-if="!isAuthenticated && twilioFlexInstanceOverride"
          id="connectButton"
          color="white"
          outline
          @click="connectToFlex"
        >
          <v-icon color="white">mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          v-if="isAuthenticated && isOnCall"
          :color="isSDR ? 'primary' : 'white'"
          :outline="!isSDR"
          @click="handleOpenTransfer"
        >
          Transfer
        </v-btn>
        <v-btn
          v-if="!isSDR && isAuthenticated && isOnCall"
          color="primary"
          @click="endCall"
        >
          End Call
        </v-btn>
        <v-btn
          v-if="
            isAuthenticated && isRinging && !isIncomingCallback && !hasCallback
          "
          color="primary"
          @click="answerCall"
        >
          Answer {{ isIncomingChat ? 'Chat' : 'Call' }}
        </v-btn>
        <v-btn
          v-if="isAuthenticated && isRinging && isIncomingCallback"
          color="primary"
          @click="acceptCallback"
        >
          Accept Callback
        </v-btn>

        <v-layout v-if="isAuthenticated && isInWrapUp" id="wrapUpButtons" row>
          <v-btn
            v-if="!hasRequestedWrapUpExtension && !isSDR && !isSDRManager"
            outline
            color="white"
            @click="extendWrapUp"
          >
            Extend WrapUp
          </v-btn>

          <v-btn color="primary" @click="completeWrapUp">Complete WrapUp</v-btn>
        </v-layout>
      </v-layout>
    </v-flex>
    <v-dialog v-model="isTransferDialogOpen" persistent max-width="500">
      <CallCenterTransferCall
        :refresh-queues="refreshQueues"
        @refresh="refreshQueues = false"
        @call-transferred="transferCall"
        @close="closeTransferDialog"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
const EVENT_LOG_SIZE = 25
const DEBUG_COUNTDOWN = 3

import { DateTime } from 'luxon'
import { mapActions } from 'vuex'
import { phoneFormatFilter, formatPhoneNumberToTenDigit } from '@/utils/phone'
import { EventBus } from '@/utils/event-bus'
import CRIcon from '@/cr/components/CRIcon.vue'
import CallCenterTransferCall from '@/components/CallCenterTransferCall.vue'
import { callCenterComputed } from '@/state/helpers'
import { callCenterMethods } from '@/state/helpers'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'
import calls from '@/services/calls'
import { saveAs } from 'file-saver'
import { SplitFeatureFlag } from '@/utils/enum'
import { createCallCenterTwilioSyncClient } from '@/utils/twilioSync'

export default {
  components: {
    CRIcon,
    CallCenterTransferCall,
  },
  props: {
    sidebarWidth: {
      type: Number,
      default: 230,
    },
    isSidebarOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isTransferDialogOpen: false,
      userStatus: null,
      refreshQueues: false,
      userStatuses: [],
      reasonTypeLevels: [],
      callCompleteReasonTypes: [],
      eventLog: [],
      debugDownloadCountdown: DEBUG_COUNTDOWN,
      isParentBrandsCallCenterEnabled: false,
      twilioSyncClient: null,
      twilioSyncStream: null,
    }
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    callCenterStatusText() {
      if (!this.isAuthenticated) {
        return 'Authentication Required. Please sign back into Flex by clicking Open Flex.'
      }
      if (this.isIncomingCallback) {
        return 'Callback Request'
      }
      if (this.isIncomingChat) {
        return 'Incoming Chat'
      }
      if (this.isRinging) {
        return 'Incoming Call'
      }
      if (this.isCalling) {
        return 'Outbound Call'
      }
      if (this.isParked) {
        return 'Call Parked'
      }
      if (this.isOnCall) {
        return 'Current Call'
      }
      if (this.hasLastChat) {
        return 'Last Chat'
      }
      if (this.hasMissedCall) {
        return 'Missed Call'
      }
      if (this.hasChat) {
        return 'Current Chat'
      }
      return 'Last Call'
    },
    callCenterStatusClass() {
      if (!this.isAuthenticated) {
        return 'need-auth'
      }
      if (this.isRinging) {
        return 'ringing'
      }
      if (this.isCalling) {
        return 'calling'
      }
      if (this.isOnHold) {
        return 'hold'
      }
      if (this.isOnCall) {
        return 'on-call'
      }
      return ''
    },
    userStatusClass() {
      if (['Parked'].includes(this.userStatus)) {
        return 'parked'
      }
      if (['Available', 'Idle', 'WrapUp'].includes(this.userStatus)) {
        return 'online'
      }
      if (['Offline'].includes(this.userStatus)) {
        return 'offline'
      }
      if (['Unavailable', 'Break'].includes(this.userStatus)) {
        return 'unavailable'
      }
      return 'unavailable'
    },
    brandPhoneDisplayText() {
      return this.phoneFormatFilter(
        formatPhoneNumberToTenDigit(this.brandPhone)
      )
    },
    brandCallerIdDisplayText() {
      if (this.isSDR || this.isSDRManager || this.isCallDirectToRAManager) {
        return this.brandCallerId
      }

      if (this.isParentBrandsCallCenterEnabled && this.parentBrandName) {
        return this.parentBrandName
      }

      return 'Unknown'
    },
    callerPhoneDisplayText() {
      let phoneNumberText = null

      if (this.isIncomingCallback || this.hasCallback) {
        phoneNumberText = this.phoneFormatFilter(
          formatPhoneNumberToTenDigit(this.callback.to)
        )
      } else {
        phoneNumberText = this.phoneFormatFilter(
          formatPhoneNumberToTenDigit(this.callerPhone)
        )
      }

      if (
        (this.isIncomingCallback || this.hasCallback) &&
        this.callback.extension
      ) {
        return phoneNumberText + ` ext. ${this.callback.extension}`
      }

      return phoneNumberText
    },
    showLastCall() {
      return (
        this.isAuthenticated &&
        !this.isRinging &&
        !this.isOnCall &&
        !this.hasMissedCall
      )
    },
    callTimeDisplayText() {
      if (this.hasLastChat) {
        return DateTime.fromFormat(
          this.lastChat?.call_accept_time,
          'yyyy-M-d h:m:s',
          { zone: 'utc' }
        )
          .toLocal()
          .toFormat('hh:mm a')
      }

      return DateTime.fromISO(this.call?.startTime).toFormat('hh:mm a')
    },
    statusTooltipText() {
      return this.callCenterUser?.status
        ? this.callCenterUser?.status
        : !this.isAuthenticated
        ? 'Needs Authentication'
        : 'Unknown'
    },
    isCallDirectToRAManager() {
      return (
        this.isRAManager &&
        this.call.currentQueue === 'SDR' &&
        !this.isIncomingTransfer
      )
    },
    callCenterUserStatuses() {
      const twilioAssignedStatuses = ['WrapUp', 'Idle', 'Parked', 'Reserved']

      return this.userStatuses.filter(
        (status) => !twilioAssignedStatuses.includes(status)
      )
    },
    userStatusesDropDownItems() {
      const dropDownItems = this.callCenterUserStatuses.map((status) => ({
        text: status,
        value: status,
      }))

      if (
        this.userStatus !== null &&
        !dropDownItems.includes(this.userStatus)
      ) {
        dropDownItems.push({
          value: this.userStatus,
          text: this.userStatus,
          disabled: true,
        })
      }
      return dropDownItems
    },
    hideStatusInDropDown() {
      return !this.callCenterUserStatuses.includes(this.userStatus)
    },
    userStatusIconClass() {
      switch (this.userStatus) {
        case 'Available':
          return 'available'
        case 'Standby':
          return 'notice'
        case 'Direct Only':
          return 'notice'
        case 'Idle':
          return 'notice'
        case 'Parked':
          return 'notice'
        default:
          return ''
      }
    },
    showUserStatusDropDown() {
      const { getters = {} } = this.$store
      return (
        !getters['systemParameters/isCallCenterCallStatusDisabled'] &&
        this.isAuthenticated
      )
    },
    twilioFlexInstanceOverride() {
      const { getters = {} } = this.$store
      return getters['systemParameters/twilioFlexInstanceOverride']
    },
    showRequeueCallbackButton() {
      return (
        this.isAuthenticated &&
        this.hasCallback &&
        !this.isOnCall &&
        !this.isRinging &&
        !this.isCalling
      )
    },
    showCompleteCallbackButton() {
      return (
        this.isAuthenticated &&
        this.hasCallback &&
        !this.isOnCall &&
        !this.isRinging &&
        !this.isCalling
      )
    },
  },
  async created() {
    if (!this.callCenter) {
      console.log('Rebuilding the call center data')
      await this.$store.dispatch('callCenter/initializeCallCenter')
    }
  },
  async mounted() {
    this.userStatus = this.callCenterUser?.status
    await this.establishBroadcastChannel()
    this.getUserFromFlex()
    this.getReasonTypes()
    await this.initializeChatOutcomes()
    await this.initializeChatReasons()
    this.isParentBrandsCallCenterEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.ParentBrandsCallCenter
    )
    if (this.chatWrapUpQueue?.length > 0) {
      this.openCompleteChatDialog(this.chatWrapUpQueue[0])
    }
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    ...callCenterMethods,
    phoneFormatFilter,
    async establishBroadcastChannel() {
      this.twilioSyncClient = await createCallCenterTwilioSyncClient()
      this.twilioSyncStream = await this.twilioSyncClient.getSyncStream()

      this.twilioSyncStream.on('messagePublished', (event) => {
        const message = event.message.data
        if (message.data?.action_name !== 'call_stats') {
          console.log('Event received in CRM page', message)
        }
        this.listenForActionsInFlex(message)
        if (message.data?.action_name === 'transfer_call') {
          this.setIsTransferred(true)
        }
      })

      // Listen for debug logs
      this.twilioSyncStream.on('messagePublished', this.addEventToLog)

      console.log('Connected to message stream')

      const {
        initialMapItems,
        twilioSyncMap,
      } = await this.twilioSyncClient.getSyncMap('WORKER_AVAILABLE_DATA')

      const agentAvailability = initialMapItems.map(
        (item) => item.descriptor.data
      )
      this.setAgentAvailability(agentAvailability)

      twilioSyncMap.on('itemUpdated', (event) => {
        const newAgentAvailability = event.item.descriptor.data
        const agentIndex = this.agentAvailability.findIndex(
          (agent) => agent.worker_sid === newAgentAvailability.worker_sid
        )
        const agentAvailability = [
          ...this.agentAvailability.slice(0, agentIndex),
          newAgentAvailability,
          ...this.agentAvailability.slice(agentIndex + 1),
        ]

        this.setAgentAvailability(agentAvailability)
        this.refreshQueues = true
        if (this.showUserStatusDropDown) {
          this.sendBroadcastMessage('get_worker', false)
        }
      })

      twilioSyncMap.on('itemAdded', (event) => {
        const newAgentAvailability = event.item.descriptor.data
        const agentAvailability = [
          ...this.agentAvailability,
          newAgentAvailability,
        ]

        this.setAgentAvailability(agentAvailability)
        this.refreshQueues = true
        if (this.showUserStatusDropDown) {
          this.sendBroadcastMessage('get_worker', false)
        }
      })

      twilioSyncMap.on('itemRemoved', (event) => {
        const deletedAgentAvailability = event.item.descriptor.data
        const agentIndex = this.agentAvailability.findIndex(
          (agent) => agent.worker_sid === deletedAgentAvailability.worker_sid
        )
        const agentAvailability = [
          ...this.agentAvailability.slice(0, agentIndex),
          ...this.agentAvailability.slice(agentIndex + 1),
        ]

        this.setAgentAvailability(agentAvailability)
        this.refreshQueues = true
        if (this.showUserStatusDropDown) {
          this.sendBroadcastMessage('get_worker', false)
        }
      })

      console.log('Connected to worker availability map object')
    },
    getUserFromFlex() {
      this.setIsAuthenticated(false)
      this.sendBroadcastMessage('get_worker', false)
      this.sendBroadcastMessage('user_data', false, {
        user_id: this.currentUser.userId,
      })
    },
    refreshAgentAvailability() {
      this.sendBroadcastMessage('get_agent_availability')
    },
    async listenForActionsInFlex(event) {
      if (
        event.data?.task_attributes?.isParkHangup &&
        ![
          event.data?.task_attributes?.call_sid,
          event.data?.task_attributes?.outbound_call_sid,
        ].includes(this.call.callSid)
      ) {
        return
      }

      if (event.data?.task_attributes?.taskType !== 'callback') {
        if (event.data?.task_attributes?.reservation_sid) {
          this.setReservationSid(event.data.task_attributes.reservation_sid)
        }
        if (event.data?.task_attributes?.task_sid) {
          this.setTaskSid(event.data.task_attributes.task_sid)
        }
      }

      if (event.data?.action_name === 'user_data') {
        this.sendBroadcastMessage('user_data', false, {
          user_id: this.currentUser.userId,
        })
      }

      if (event.data?.action_name === 'call_stats') {
        // const teamStatsResponse = await this.$store.dispatch(
        //   'agents/getTeamStats'
        // )
        const teamStats = {
          // longestHoldDuration: teamStatsResponse.data.longestHoldDuration,
          longestHoldDuration: 0,
          agentsOnCalls: event.data.agents_on_call,
          agentsAvailable: event.data.available_agents,
          callsInQueue: event.data.calls_in_queue,
        }
        this.setTeamStats(teamStats)
        return
      }
      if (event.data?.action_name === 'task_incoming') {
        if (event.data?.task_attributes?.taskType === 'callback') {
          this.clearLastChat()
          this.setCallback(event.data?.task_attributes)
          this.setIsIncomingCallback(true)
          this.setIsRinging(true)
          return
        } else if (
          event.data?.task_attributes?.lookupCallerType === 'Webchat'
        ) {
          this.setIncomingChatName(event.data?.task_attributes?.name)
          this.setIsIncomingChat(true)
          this.setIsRinging(true)
          return
        }

        this.clearLastChat()

        this.populateCallData(event.data?.task_attributes)
        if (event.data?.task_attributes?.isParkPickup) {
          return
        }

        if (event.data?.task_attributes?.direction === 'outbound') {
          this.setToOutboundCall()
        } else {
          this.setToIncomingCall()
        }
        return
      }
      if (event.data?.action_name === 'task_outgoing') {
        this.populateCallData(event.data?.task_attributes)
        if (event.data?.task_attributes?.isParkPickup) {
          return
        }
        this.setToOutboundCall()
        return
      }
      if (event.data?.action_name === 'worker_data') {
        this.setIsAuthenticated(true)
        const userData = event.data.worker_attributes
        this.setCallCenterUser({
          status: userData.activity_name,
          phone: this.processPhoneNumber(userData.callerId),
          contactUri: userData.contact_uri,
          email: userData.email,
          fullName: userData.full_name,
        })
        this.userStatus = this.callCenterUser?.status
        if (this.showUserStatusDropDown) {
          this.userStatuses = event.data.activity_names
        }

        const chats = event.data.assigned_tasks
          .filter((task) => task.channelType === 'web')
          .map((task) => task.attributes)
        this.setChats(chats)
        return
      }
      if (event.data?.action_name === 'worker_activity_update') {
        const userData = event.data.worker_attributes
        this.setCallCenterUser({
          status: userData.activity_name,
          phone: this.processPhoneNumber(userData.callerId),
          contactUri: userData.contact_uri,
          email: userData.email,
          fullName: userData.full_name,
        })
        this.completeWrapUpOnStatusChange(this.callCenterUser?.status)
        this.userStatus = this.callCenterUser?.status
        return
      }
      if (
        event.data?.action_name === 'task_cancel' ||
        (event.data?.action_name === 'task_timeout' &&
          event.data?.task_attributes?.direction === 'inbound')
      ) {
        if (event.data?.task_attributes?.taskType === 'callback') {
          this.setIsIncomingCallback(false)
        } else if (
          event.data?.task_attributes?.lookupCallerType === 'Webchat'
        ) {
          this.setIsIncomingChat(false)
        }
        this.setToMissedCall()
        return
      }
      if (event.data?.action_name === 'task_rejected') {
        if (event.data?.task_attributes?.taskType === 'callback') {
          this.setIsIncomingCallback(false)
        } else if (
          event.data?.task_attributes?.lookupCallerType === 'Webchat'
        ) {
          this.setIsIncomingChat(false)
        }
        this.setToMissedCall()
        return
      }
      if (event.data?.action_name === 'task_accepted') {
        if (event.data?.task_attributes?.taskType === 'callback') {
          this.setHasCallback(true)
          this.setIsIncomingCallback(false)
          this.setToAcceptedCallback()
          return
        } else if (
          event.data?.task_attributes?.lookupCallerType === 'Webchat'
        ) {
          this.addChat(event.data.task_attributes)
          this.setIsIncomingChat(false)
          this.clearLastChat()
          this.setToAcceptedCallback()
          return
        }

        this.setToOnCall()
        return
      }
      if (event.data?.action_name === 'task_hold') {
        this.setStateHold()
        return
      }
      if (event.data?.action_name === 'task_unhold') {
        this.setStateResume()
        return
      }
      if (event.data?.action_name === 'toggle_mute') {
        this.setStateMute(event.data.is_muted)
        return
      }
      if (event.data?.action_name === 'task_wrapup') {
        this.setToEndCall()
        if (
          event.data?.task_attributes?.conversations?.outcome === 'Parked Call'
        ) {
          this.setIsParked(true)
          return
        }

        // chats skip wrapup for now
        if (event.data?.task_attributes?.lookupCallerType === 'Webchat') {
          this.wrapUpChat(event.data.task_attributes)
          return
        }

        // call was ended in flex, need to fill out form still
        this.setWrapUp({
          task_sid: event.data.task_attributes.task_sid,
          reservation_sid: event.data.task_attributes.reservation_sid,
          call_sid: event.data.task_attributes.call_sid,
        })
        this.setIsInWrapUp(true)
        this.wrapUpCall()
        return
      }
      if (event.data?.action_name === 'task_completed') {
        // call was completed in flex, need to fill out form still
        const taskType = event.data?.task_attributes?.taskType
        const reservationSid = event.data?.task_attributes?.reservation_sid
        const taskSid = event.data?.task_attributes?.task_sid
        if (
          taskType === 'callback' &&
          this.callback.reservation_sid === reservationSid &&
          this.callback.task_sid === taskSid
        ) {
          this.setHasCallback(false)
        } else if (this.wrapUp?.task_sid === this.taskSid) {
          this.setIsInWrapUp(false)
        }
        return
      }
    },
    findFlexTab() {
      const data = { action: 'focus_flex' }
      window.parent.postMessage(data)
    },
    connectToFlex() {
      const baseUrl = 'http://flex.twilio.com/'
      window.open(baseUrl + this.twilioFlexInstanceOverride, '_blank')
    },
    answerCall() {
      this.sendBroadcastMessage('answer_call')
    },
    toggleMute() {
      this.sendBroadcastMessage('toggle_mute', false)
    },
    putCallOnHold() {
      this.sendBroadcastMessage('hold_call')
    },
    resumeCall() {
      this.sendBroadcastMessage('unhold_call')
    },
    transferCall(transferOptions) {
      this.sendBroadcastMessage('transfer_call', true, {
        mode: 'COLD',
        targetSid: transferOptions.target,
        transfer_type: transferOptions.type,
      })
      this.setIsTransferred(true)
    },
    closeTransferDialog() {
      this.isTransferDialogOpen = false
    },
    endCall() {
      this.sendBroadcastMessage('end_call')
    },
    async wrapUpCall() {
      const endedCallSid = deepClone(this.call.callSid)
      if (!this.reasonTypeLevels?.length) {
        await this.getReasonTypes()
      }
      this.$store.dispatch('app/openDialog', {
        component: () => import('./CallCenterCompleteCall.vue'),
        data: {
          endedCallSid: endedCallSid,
          reasonTypeLevels: this.reasonTypeLevels,
        },
      })
      EventBus.$on('call-completed', () => {
        this.sendBroadcastMessage('complete_call', true, {}, true)
      })
    },
    wrapUpChat(chat) {
      this.addToChatWrapUpQueue(chat)
      if (this.chatWrapUpQueue.length === 1) {
        this.openCompleteChatDialog(chat)
      }
      this.removeChat(chat)
    },
    acceptCallback() {
      this.sendCallbackBroadcastMessage('accept_callback')
    },
    requeueCallback() {
      this.sendCallbackBroadcastMessage('requeue_callback')
    },
    completeCallback() {
      this.sendCallbackBroadcastMessage('complete_call')
    },

    sendBroadcastMessage(
      actionName,
      includeSids = true,
      additionalData,
      usePreviousSids = false
    ) {
      let optionalParams = {}
      let params = {}
      const standardParams = {
        type: 'to_flex',
        action_name: actionName,
      }

      if (includeSids) {
        if (usePreviousSids) {
          optionalParams.reservation_sid = this.previousCall?.reservationSid
          optionalParams.task_sid = this.previousCall?.taskSid
        } else {
          optionalParams.reservation_sid = this.reservationSid
          optionalParams.task_sid = this.taskSid
        }
      }
      params = Object.assign(
        params,
        standardParams,
        optionalParams,
        additionalData
      )

      this.twilioSyncStream.publishMessage({ data: params })
    },
    sendCallbackBroadcastMessage(actionName, additionalData) {
      const callbackSids = {
        reservation_sid: this.callback.reservation_sid,
        task_sid: this.callback.task_sid,
        taskType: 'callback',
      }
      const messageData = Object.assign(callbackSids, additionalData)

      this.sendBroadcastMessage(actionName, false, messageData)
    },
    sendWrapUpBroadcastMessage(actionName) {
      this.sendBroadcastMessage(actionName, false, this.wrapUp)
    },
    setStateHold() {
      this.setIsOnHold(true)
    },
    setStateResume() {
      this.setIsOnHold(false)
    },
    setStateMute(isMuted) {
      this.setIsOnMute(isMuted)
    },
    populateCallData(callInfo) {
      this.setCall({
        startTime: DateTime.local().toISO(),
        callerName: callInfo.lookupCallerName,
        callerPhone: ['outbound', 'outbound-api'].includes(callInfo?.direction)
          ? callInfo.outbound_to
          : callInfo.from,
        brandName: callInfo.brand_name,
        brandCallerId: callInfo.caller_id,
        brandPhone: callInfo.to,
        callSid: callInfo.call_sid,
        isIncomingTransfer: callInfo?.status === 'transferred',
        isTransferred: false,
        queueTransferredFrom: callInfo.queue_before_transfer,
        currentQueue: callInfo.queue_name,
        parentBrandName: callInfo.parent_brand_name,
        tierKey: callInfo.tierKey,
      })
    },
    processPhoneNumber(number) {
      let callerNumber = null
      if (number[0] === '+') {
        if (number.length === 12) {
          callerNumber = number.substr(2)
        }
        callerNumber = number.substr(1)
      }
      return callerNumber
    },
    updateFlexUserStatus(status) {
      this.sendBroadcastMessage('update_agent_activity', false, {
        activity_name: status,
      })
    },
    async handleOpenTransfer() {
      await this.refreshAgentAvailability()
      this.refreshQueues = true
      this.isTransferDialogOpen = true
    },
    async getReasonTypes() {
      const callReasonTypes = await this.$store.dispatch(
        'callCenter/getReasonTypes'
      )
      this.$store.dispatch('callCenter/setCallReasonTypes', callReasonTypes)

      let callCompleteReasonTypes = await calls.getCallCompleteReasonTypes()
      callCompleteReasonTypes = callCompleteReasonTypes.data.data
      this.$store.dispatch(
        'callCenter/setCallCompleteReasonTypes',
        callCompleteReasonTypes
      )
      for (let reason of callCompleteReasonTypes) {
        this.addReasonAndChildren(reason, 0)
      }
    },
    addReasonAndChildren(reason, level) {
      if (reason == null) return
      if (this.reasonTypeLevels.length <= level) this.reasonTypeLevels.push([])
      this.reasonTypeLevels[level].push(reason)
      if (reason.childClassifications == null) return
      for (let child of reason.childClassifications) {
        this.addReasonAndChildren(child, level + 1)
      }
    },
    addEventToLog(event) {
      if (this.eventLog.length === EVENT_LOG_SIZE) {
        this.eventLog.shift()
      }
      this.eventLog.push({ ...event.data, timestamp: Date.now() })
    },
    exportDebugData() {
      const callCenterExport = {
        eventLog: this.eventLog,
        callCenterStatus: this.callCenter,
      }
      const callCenterStatusDataBlob = new Blob(
        [JSON.stringify(callCenterExport)],
        { type: 'text/json;charset=utf-8' }
      )
      saveAs(callCenterStatusDataBlob, `call_center_debug_${Date.now()}.json`)
    },
    debugFileCountdown() {
      this.debugDownloadCountdown -= 1
      if (this.debugDownloadCountdown === 0) {
        this.debugDownloadCountdown = DEBUG_COUNTDOWN
        this.exportDebugData()
      }
    },
    extendWrapUp() {
      this.sendWrapUpBroadcastMessage('extend_wrapup')
      this.setHasRequestedWrapUpExtension(true)
    },
    completeWrapUp() {
      this.sendWrapUpBroadcastMessage('complete_call')
    },
    completeWrapUpOnStatusChange(status) {
      if (this.isInWrapUp && status !== 'WrapUp') {
        this.completeWrapUp()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$call-center-min-height: 60px;
$call-center-border-radius: 15px;
$call-center-top-padding: 10px;
$call-center-side-padding: $call-center-border-radius;
$icon-circle-height: $call-center-min-height - (2 * $call-center-top-padding);
$margin-right: $call-center-top-padding;

.call-center {
  min-height: $call-center-min-height;
  padding: $call-center-top-padding $call-center-border-radius;
  border-radius: $call-center-border-radius;
  background: $blue-gray;
  margin-right: 156px;
  position: fixed;
  top: 10px;
  z-index: 11;
  &.need-auth {
    background: $gray-medium-light;
  }
  &.ringing,
  &.calling {
    animation: ringing-pulse 3s ease-in-out infinite;
    @keyframes ringing-pulse {
      0% {
        background-color: $success;
      }
      50% {
        background-color: $blue-gray;
      }
      100% {
        background-color: $success;
      }
    }
  }
  &.ringing {
    transform: translate3d(0, 0, 0);
    animation: ringing-pulse 3s ease-in-out infinite,
      shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(1px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-1px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(1px, 0, 0);
      }
    }
  }
  &.hold {
    animation: hold-pulse 3s ease-in-out infinite;
    @keyframes hold-pulse {
      0% {
        background-color: $warning;
      }
      50% {
        background-color: $blue-gray;
      }
      100% {
        background-color: $warning;
      }
    }
  }

  .status-container {
    margin-right: $margin-right * 2;
  }

  .display-status {
    color: $white;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;

    span.call-time {
      font-size: 14px;
    }
  }

  .caller-details {
    color: $white;
    font-size: 16px;
    pointer-events: none;
  }

  .caller-details-arrow {
    font-size: 16px;
    margin-left: 8px;
  }

  .icon-circle {
    background: $white;
    height: $icon-circle-height;
    width: $icon-circle-height;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $margin-right;
    .v-icon {
      cursor: default;
      color: $gray;
      &.online {
        color: $success;
      }
      &.unavailable {
        color: $error;
      }
      &.offline {
        color: $gray;
      }
      &.parked {
        color: $yellow;
      }
    }
  }
  .v-btn {
    min-width: 0;
  }

  .icon-status-circle {
    background: $gray-light;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $margin-right;
    &.available {
      background: $success;
    }
    &.notice {
      background: $warning;
    }
  }

  .icon-btn {
    padding-left: 8px;
    padding-right: 8px;
  }

  .subtitle-text {
    color: $gray-medium-light;
    font-size: 14px;
    line-height: normal;
  }

  .status-box {
    white-space: nowrap;
  }

  ::v-deep {
    .v-select {
      width: min-content;
      height: 36px;
      margin-right: 8px;
      margin-left: 8px;

      .v-input__slot {
        border: 1px solid $white !important;
        min-height: 36px;
        &:hover {
          background-color: rgba($white, 0.25) !important;
        }
      }

      .v-select__selections {
        color: $white !important;
        padding-top: 0px;
      }

      .v-input__append-inner {
        margin-top: 6px;
      }

      .v-icon {
        color: $white !important;
        caret-color: $white !important;
      }
    }
  }
}
</style>
